import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { adminRoot, currentUser } from '../../constants/defaultValues';
import { auth } from '../../helpers/Firebase';
import IntlMessages from '../../helpers/IntlMessages';
import { setCurrentUser } from '../../helpers/Utils';
import AuthService from '../../services/AuthService';
import {
  FORGOT_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  SOCIAL_LOGIN_USER,
  SOCIAL_REGISTER_USER,
} from '../actions';
import {
  forgotPasswordError,
  forgotPasswordSuccess,
  loginUserError,
  loginUserSuccess,
  registerUserError,
  resetPasswordError,
  resetPasswordSuccess,
} from './actions';

const pathRedirectToPlans = '/redirect?to=plans';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

function* loginWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(AuthService.login, payload.user);

    if (loginUser?.token) {
      if (loginUser === 'INVALID_USER') {
        yield put(loginUserError(<IntlMessages id='apiErrors.invalidUser' />));
        return;
      }

      const item = { uid: loginUser, type: 'USER', ...currentUser };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));

      if (item) {
        if (payload.user?.return_to_redirect) {
          setTimeout(() => {
            history.push(pathRedirectToPlans);
          }, 300);
        } else {
          history.push(adminRoot);
        }
      }
    } else {
      yield put(loginUserError(<IntlMessages id='apiErrors.unableToLog' />));
    }
  } catch (error) {
    if (error === 'NO_INTERNET') {
      yield put(loginUserError(<IntlMessages id='networkError.noInternet' />));
      return;
    }
    if (error?.response?.data[0] === 'INVALID_USER') {
      yield put(loginUserError(<IntlMessages id='apiErrors.invalidUser' />));
      return;
    }
    yield put(loginUserError(<IntlMessages id='apiErrors.serverError' />));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

function* registerWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const registerUser = yield call(AuthService.register, payload.user);
    if (!registerUser.message) {
      const loginUser = yield call(AuthService.login, payload.user);

      if (loginUser?.token) {
        if (loginUser === 'INVALID_USER') {
          yield put(
            loginUserError(<IntlMessages id='apiErrors.invalidUser' />)
          );
          return;
        }
        const item = { uid: loginUser, type: 'USER', ...currentUser };
        setCurrentUser(item);
        yield put(loginUserSuccess(item));
        if (item) {
          if (payload.user?.return_to_redirect)
            setTimeout(() => {
              history.push(pathRedirectToPlans);
            }, 300);
          else history.push(adminRoot);
        }
      } else {
        history.push('/');
        yield put(
          registerUserError(
            <IntlMessages id='apiErrors.unableToLogAfterRegister' />
          )
        );
        return;
      }
    } else {
      yield put(
        registerUserError(<IntlMessages id='apiErrors.unableToRegister' />)
      );
      return;
    }
  } catch (error) {
    if (error?.response?.data.error === 'EMAIL_EXISTS') {
      yield put(registerUserError(<IntlMessages id='apiErrors.emailExist' />));
      return;
    }

    yield put(
      registerUserError(<IntlMessages id='apiErrors.unableToRegister' />)
    );
  }
}

export function* watchSocialLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SOCIAL_LOGIN_USER, socialloginWithEmail);
}

function* socialloginWithEmail({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(AuthService.socialLogin, payload.user);

    if (loginUser?.token) {
      if (loginUser === 'INVALID_USER') {
        yield put(loginUserError(<IntlMessages id='apiErrors.invalidUser' />));
        return;
      }
      const item = { uid: loginUser, type: 'USER', ...currentUser };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      if (item) {
        if (payload.user?.return_to_redirect) {
          setTimeout(() => {
            history.push(pathRedirectToPlans);
          }, 300);
        } else {
          history.push(adminRoot);
        }
      }
    } else {
      yield put(loginUserError(<IntlMessages id='apiErrors.unableToLog' />));
    }
  } catch (error) {
    if (error === 'NO_INTERNET') {
      yield put(loginUserError(<IntlMessages id='networkError.noInternet' />));
      return;
    }
    if (error?.response?.data[0] === 'INVALID_USER') {
      yield put(loginUserError(<IntlMessages id='apiErrors.invalidUser' />));
      return;
    }
    yield put(loginUserError(<IntlMessages id='apiErrors.serverError' />));
  }
}

export function* watchSocialRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SOCIAL_REGISTER_USER, SocialRegisterWithEmail);
}

function* SocialRegisterWithEmail({ payload }) {
  const { history } = payload;
  try {
    const registerUser = yield call(AuthService.socialRegister, payload.user);
    if (!registerUser.message) {
      const loginUser = yield call(AuthService.socialLogin, payload.user);

      if (loginUser?.token) {
        if (loginUser === 'INVALID_USER') {
          yield put(
            loginUserError(<IntlMessages id='apiErrors.invalidUser' />)
          );
          return;
        }
        const item = { uid: loginUser, type: 'USER', ...currentUser };
        setCurrentUser(item);
        yield put(loginUserSuccess(item));
        if (item) {
          if (payload.user?.return_to_redirect)
            setTimeout(() => {
              history.push(pathRedirectToPlans);
            }, 300);
          else history.push(adminRoot);
        }
      } else {
        history.push('/');
        yield put(
          registerUserError(
            <IntlMessages id='apiErrors.unableToLogAfterRegister' />
          )
        );
        return;
      }
    } else {
      yield put(
        registerUserError(<IntlMessages id='apiErrors.unableToRegister' />)
      );
      return;
    }
  } catch (error) {
    if (error?.response?.data.error === 'EMAIL_EXISTS') {
      yield put(registerUserError(<IntlMessages id='apiErrors.emailExist' />));
      return;
    }

    yield put(
      registerUserError(<IntlMessages id='apiErrors.unableToRegister' />)
    );
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async history => {
  await auth
    .signOut()
    .then(user => user)
    .catch(error => error);
  history.push('/');
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser({});
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async email => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then(user => user)
    .catch(error => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then(user => user)
    .catch(error => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchSocialLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchSocialRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
