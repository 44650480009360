/* eslint-disable class-methods-use-this */
import api from '../API/config';

class AuthService {
  async login(user) {
    const body = {
      email: user.email,
      password: user.password,
    };
    try {
      const { data } = await api.post(`${api.authURL}`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      if (error === 'NO_INTERNET') {
        return Promise.reject(error, 'NO_INTERNET');
      }
      if (error?.response.data[0] === 'INVALID_USER') {
        return Promise.reject(error, 'INVALID_USER');
      }
      return null;
    }
  }

  async register(user) {
    const body = {
      email: user.email,
      password: user.password,
      name: user.name,
      phone: user.phone,
    };
    try {
      const { data } = await api.post(`user/register/`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      if (error === 'NO_INTERNET') {
        return Promise.reject(error, 'NO_INTERNET');
      }
      if (error?.response.data.error === 'EMAIL_EXISTS') {
        return Promise.reject(error, 'EMAIL_EXISTS');
      }
      return null;
    }
  }

  async socialLogin(user) {
    const body = {
      access_token: user.access_token,
      platform: user.platform,
    };
    try {
      const { data } = await api.post(`user/social/login/`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      if (error === 'NO_INTERNET') {
        return Promise.reject(error, 'NO_INTERNET');
      }
      if (error?.response.data[0] === 'INVALID_USER') {
        return Promise.reject(error, 'INVALID_USER');
      }
      return null;
    }
  }

  async socialRegister(user) {
    const body = {
      email: user.email,
      name: user.name,
      platform: user.platform,
    };
    try {
      const { data } = await api.post(`user/social/register/`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      if (error === 'NO_INTERNET') {
        return Promise.reject(error, 'NO_INTERNET');
      }
      if (error?.response.data.error === 'EMAIL_EXISTS') {
        return Promise.reject(error, 'EMAIL_EXISTS');
      }
      return null;
    }
  }

  async getNotificationsList(token) {
    try {
      const { data } = await api.get('notification/notify/list/', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async getTermsAndPolicy() {
    try {
      const { data } = await api.get('settings/terms-and-policy/', {});

      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async confirmUserEmail(hashcod) {
    try {
      const { data } = await api.get(`user/register/confirm/${hashcod}/`, {});

      if (data && data?.detail) {
        return data;
      }
      return null;
    } catch (error) {
      if (
        error?.response?.data?.detail ===
        'You do not have permission to access this link.'
      ) {
        return 'INVALID_LINK';
      }

      return null;
    }
  }
}

export default new AuthService();
