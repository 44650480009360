import axios from 'axios';

import { NotificationManager } from '../components/common/react-notifications';
import IntlMessages from '../helpers/IntlMessages';

let conected = window.navigator.onLine;

window.addEventListener('online', function () {
  conected = true;
});

window.addEventListener('offline', function () {
  conected = false;
});

const url =
  process.env.NODE_ENV === 'production'
    ? `https://hom.api.vemprotribe.com`
    : `https://hom.api.vemprotribe.com`;

export const chat_url =
  process.env.NODE_ENV === 'production'
    ? `wss://hom.api.vemprotribe.com`
    : `wss://hom.api.vemprotribe.com`;

export const urlDashboard =
  process.env.NODE_ENV === 'production'
    ? `https://www.app.vemprotribe.com`
    : `https://www.hom.app.vemprotribe.com`;

const api = axios.create({
  baseURL: `${url}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!conected) {
      return Promise.reject('NO_INTERNET');
    }

    if (error?.response && error.response.status === 401) {
      NotificationManager.error(
        <IntlMessages id='generics.sessionExpired' />,
        <IntlMessages id='generics.error' />,
        7500,
        null,
        null,
        ''
      );
    }

    return Promise.reject(error);
  }
);

api.authURL = `${url}/api/v1/api-token-auth/user/`;
api.authRefreshURL = `${url}/api/v1/api-token-refresh/`;
api.url = url;

export default api;
